import { InjectionToken, inject } from '@angular/core';

const VISIBLE_ICONS_TOKEN = new InjectionToken('VISIBLE_ICON_TOKEN');

export interface IconNames {
	invisibleIcon: string;
	visibleIcon: string;
}

export function provideIconNames(config: IconNames) {
	return {
		provide: VISIBLE_ICONS_TOKEN,
		useValue: config,
	};
}

export function getIconNames(): IconNames {
	return inject(VISIBLE_ICONS_TOKEN) as IconNames;
}
